
export const THINKING_TIME = 5 
export const TOTAL_TIME = 15

export const BASE_RATE="$13/hr"

export const GAME_COUNT=5

export const BONUS="$0.50"

export const THINKING_TIME_RESPOND=30

export const DRAW_OUTCOME_TXT = "The game has ended in a draw.";
export const LOSS_OUTCOME_TXT = "You lost :(";
export const WIN_OUTCOME_TXT = "You won!";
